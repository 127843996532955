body{
    padding: 0;
    margin: 0;
}
#root{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
